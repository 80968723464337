<template>
    <div class="container">
        <div class="title">{{ name }}用户注册协议</div>
        <div class="content" v-html="protocol"></div>
    </div>
</template>

<script>
import { protocol } from "./json";
import settings from "@/settings";

export default {
    name: "Protocol",
    setup() {
        return { protocol, name: settings.name };
    },
};
</script>

<style lang="less" scoped>
.container {
    padding: 20px 30px;
    .title {
        font-size: 16px;
        color: #3b3b3b;
        text-align: center;
        padding-bottom: 15px;
    }
    .content {
        font-size: 14px;
        color: #8c8c8c;
    }
}
</style>
